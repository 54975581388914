
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import SelectLocationModal from "@/components/modals/forms/SelectLocationModal.vue";
export default defineComponent({
  name: "contacts-base",
  components: {
    SelectLocationModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Manage Contacts");
    });
  },
});
