import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "sbmx_modal_select_user_location",
  ref: "selectLocationRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog mw-650px" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = { class: "modal-header pb-0 border-0 justify-content-end" }
const _hoisted_5 = { class: "modal-title w-100" }
const _hoisted_6 = { class: "modal-body scroll-y" }
const _hoisted_7 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_8 = { class: "border-bottom border-dashed" }
const _hoisted_9 = { class: "text-start ps-6 fs-4" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, " Selected Location: " + _toDisplayString(this.$store.state.AuthModule.selectedLocation.name), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _createElementVNode("tbody", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$store.state.AuthModule.user.userLocations, (ul) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: ul,
                  class: _normalizeClass(["fw-semibold fs-6 text-gray-800 text-center", {
                  'bg-secondary':
                    ul.location.id ===
                    this.$store.state.AuthModule.selectedLocation.id,
                }])
                }, [
                  _createElementVNode("td", _hoisted_9, _toDisplayString(ul.location.name), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("button", {
                      class: _normalizeClass([{
                      disabled:
                        ul.location.id ===
                        this.$store.state.AuthModule.selectedLocation.id,
                    }, "btn btn-primary align-self-center ms-5"]),
                      type: "button",
                      onClick: ($event: any) => (this.selectLocation(ul.location))
                    }, " Select ", 10, _hoisted_10)
                  ])
                ], 2))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ], 512))
}