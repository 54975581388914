
import {
  computed,
  defineComponent,
  onActivated,
  onMounted,
  ref,
  watch,
} from "vue";

import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Location, UserLocation } from "@/store/modules/AuthModule";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "select-location-modal",
  components: {},

  setup() {
    const store = useStore();
    const selectLocationRef = ref<null | HTMLElement>(null);

    const selectLocation = function (newLoc: Location) {
      store.commit(Mutations.SET_SELECTED_LOCATION, newLoc);
      hideModal(selectLocationRef.value);
    };

    return {
      selectLocation,
      selectLocationRef,
    };
  },
});
